import requset, { RequestResult } from '@/utils/ajax';
import dayjs from 'dayjs';
import { store } from '@/store';

export interface GetCallHistoryParams {
  queryType: string; // 0 全部通话 1 未接来电 2呼出记录 3内部通话 4呼入记录
  page: number; // 页码
  length: number; // 一页长度
}

export enum HangupCause {
  UNSPECIFIED = '未指定，没有其他原因代码适用',
  UNALLOCATED_NUMBER = '空号',
  NO_ROUTE_TRANSIT_NET = '没有指定过境网络的路线',
  NO_ROUTE_DESTINATION = '无目的地路由',
  CHANNEL_UNACCEPTABLE = '频道不可接收',
  CALL_AWARDED_DELIVERED = '呼叫被授予，在已建立的渠道中传送',
  NORMAL_CLEARING = '正常通话清算',
  USER_BUSY = '用户忙',
  NO_USER_RESPONSE = '没有用户响应',
  NO_ANSWER = '用户没有应答',
  SUBSCRIBER_ABSENT = '订户缺席',
  CALL_REJECTED = '呼叫被拒绝',
  NUMBER_CHANGED = '编号已更改',
  REDIRECTION_TO_NEW_DESTINATION = 'ISUP 协议机制',
  EXCHANGE_ROUTING_ERROR = '无法到达用户指示的目的地',
  DESTINATION_OUT_OF_ORDER = '目的地故障',
  INVALID_NUMBER_FORMAT = '无效的数字格式（地址不完整)',
  FACILITY_REJECTED = '设备被拒绝',
  RESPONSE_TO_STATUS_ENQUIRY = '对状态查询的回应',
  NORMAL_UNSPECIFIED = '正常的，未指定的',
  NORMAL_CIRCUIT_CONGESTION = '没有可用的电路/通道',
  NETWORK_OUT_OF_ORDER = '网络故障',
  NORMAL_TEMPORARY_FAILURE = '正常临时失败',
  SWITCH_CONGESTION = '交换设备拥塞',
  ACCESS_INFO_DISCARDED = '访问信息被丢弃',
  REQUESTED_CHAN_UNAVAIL = '请求的电路/通道不可用',
  PRE_EMPTED = 'PRE_EMPTED',
  FACILITY_NOT_SUBSCRIBED = '请求的设备未订阅',
  OUTGOING_CALL_BARRED = '禁止拨出电话',
  INCOMING_CALL_BARRED = '来电被禁止',
  BEARERCAPABILITY_NOTAUTH = '未经授权的承载能力',
  BEARERCAPABILITY_NOTAVAIL = '承载能力目前不可用',
  SERVICE_UNAVAILABLE = '服务不可用',
  BEARERCAPABILITY_NOTIMPL = '未实现承载能力',
  CHAN_NOT_IMPLEMENTED = '通道类型未实现',
  FACILITY_NOT_IMPLEMENTED = '请求的设备未实施',
  SERVICE_NOT_IMPLEMENTED = '未实施的服务或选项，未指定',
  INVALID_CALL_REFERENCE = '无效调用参考值',
  INCOMPATIBLE_DESTINATION = '不兼容的目的地',
  INVALID_MSG_UNSPECIFIED = '无效消息，未指定',
  MANDATORY_IE_MISSING = '缺少强制性信息元素',
  MESSAGE_TYPE_NONEXIST = '消息类型不存在或未实现',
  WRONG_MESSAGE = '错误讯息',
  IE_NONEXIST = '信息元素/参数不存在或未实施',
  INVALID_IE_CONTENTS = '无效的信息元素内容',
  WRONG_CALL_STATE = '消息与呼叫状态不兼容',
  RECOVERY_ON_TIMER_EXPIRE = '定时器超时恢复',
  MANDATORY_IE_LENGTH_ERROR = '参数不存在或未实现 - 传递',
  PROTOCOL_ERROR = '协议错误',
  INTERWORKING = '互通，未指定',
  ORIGINATOR_CANCEL = '取消呼叫',
  TIMEOUT = '超时',
  CRASH = '崩溃',
  SYSTEM_SHUTDOWN = '系统停止',
  LOSE_RACE = 'LOSE_RACE',
  MANAGER_REQUEST = 'MANAGER_REQUEST',
  BLIND_TRANSFER = '忙线转移',
  ATTENDED_TRANSFER = '指定转接',
  ALLOTTED_TIMEOUT = '分配超时',
  USER_CHALLENGE = 'USER_CHALLENGE',
  MEDIA_TIMEOUT = '媒体超时',
  PICKED_OFF = 'PICKED_OFF',
  USER_NOT_REGISTERED = '用户未注册',
  PROGRESS_TIMEOUT = '进度超时',
  GATEWAY_DOWN = '网关下降（未回答选项或订阅）',
}
export type HangupCauseKeys = keyof typeof HangupCause;
// 后端返回的数据类型
export interface GetCallHistoryListItem {
  answer_epoch: number | string;
  answer_stamp: string;
  billsec: number;
  caller_destination: string;
  caller_id_number: string;
  direction: string;
  duration: number;
  end_epoch: number;
  end_stamp: string;
  extension_uuid: string;
  hangup_cause: HangupCauseKeys;
  record_name: null | string;
  record_path: null | string;
  sip_from_to_tag: string;
  sip_hangup_disposition: string;
  start_epoch: number;
  start_stamp: string;
  xml_cdr_uuid: string;
  /** 去除*号码 */
  number?: string;
  /** 开始时间 */
  startTime?: string;
  /** 结束时间 */
  endTime?: string;
  /** 通话时长 */
  time?: string;
}
// 处理后
export interface GetCallHistoryListResult {
  items: GetCallHistoryListItem[];
  length: number;
  page: number;
  total: number;
}

// 秒转化成 时分秒
const secondToDate = (sec: number) => {
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec / 60) % 60);
  const s = Math.floor(sec % 60);
  const strH = h < 10 ? `0${h}` : h;
  const strM = m < 10 ? `0${m}` : m;
  const strS = s < 10 ? `0${s}` : s;
  return `${strH}:${strM}:${strS}`;
};

/** 获取拨号历史记录 */
export function getCallHistoryList(postData: GetCallHistoryParams) {
  return new Promise<RequestResult<GetCallHistoryListResult>>(
    (resolve, reject) => {
      requset<GetCallHistoryListResult>({
        url: '/FcomHuihu/V1/Client/AllCallList',
        method: 'GET',
        params: postData,
      })
        .then((res) => {
          if (res.code === '000000') {
            res.data.items = res.data.items.map((item) => {
              const startTime = dayjs
                .unix(item.start_epoch)
                .format('YYYY-MM-DD HH:mm:ss');
              const endTime = dayjs
                .unix(item.end_epoch)
                .format('YYYY-MM-DD HH:mm:ss');
              const time = secondToDate(item.end_epoch - item.start_epoch);

              // 判断显示对方号码
              let number =
                item.direction === 'outbound'
                  ? item.caller_destination
                  : item.caller_id_number;
              // 去除号码*前缀
              const numberArr = number.split('*');
              if (numberArr.length > 1) {
                // eslint-disable-next-line prefer-destructuring
                number = numberArr[1];
              }
              return {
                ...item,
                number,
                startTime,
                endTime,
                time,
              };
            });
            store.dispatch({
              type: 'UPDATE_UNREAD_MISSED_CALL_COUNT',
              payload: 0,
            });
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    },
  );
}
