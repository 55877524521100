import getUserInfo from './getUserInfo';

// 0-无 1-A类
export default function checkExtionType(data: number) {
  const useInfo = getUserInfo();
  if (useInfo?.extensionType === data) {
    return true;
  }
  return false;
}
