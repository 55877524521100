import { useRoutes } from 'react-router';
import { RouteObject } from 'react-router-dom';

import { SettingFilled } from '@ant-design/icons';
import React, {
  ComponentType,
  lazy,
  Suspense,
  useEffect,
  useState,
} from 'react';
import IconFont from 'src/components/Iconfont';
import { useLocation } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import Sip from '@/utils/sip';
import { RootState } from '@/store';
import {
  getCallHistoryList,
  GetCallHistoryParams,
} from '@/api/call-history/getCallHistoryList';
import { bindRemark } from '@/api/remak/bindRemark';
import { message } from 'antd';
import { hangupinvisibility } from '@/api/login';
import getUserInfo from '@/utils/getUserInfo';
import checkExtionType from '@/utils/checkExtionType';
import JsSIP from 'jssip';

const lazyLoad = (src: () => Promise<{ default: ComponentType<any> }>) => (
  <Suspense fallback={<></>}>{React.createElement(lazy(src))}</Suspense>
);

const Layout = lazyLoad(() => import('@/components/Layout/index'));
const Login = lazyLoad(() => import('@/pages/account/login'));
const Home = lazyLoad(() => import('@/pages/home/index'));
/**群呼列表 */
const GroupCallList = lazyLoad(() => import('@/pages/group-call/list'));
const GroupCallDetails = lazyLoad(() => import('@/pages/group-call/details'));
/**通话记录 */
const CallHistoryList = lazyLoad(() => import('@/pages/call-history/list'));
/**拨打计划 */
const CallPlanList = lazyLoad(() => import('@/pages/call-plan/list'));
const CallPlanDetails = lazyLoad(() => import('@/pages/call-plan/details'));
const CallPlanHistoryList = lazyLoad(
  () => import('@/pages/call-plan/history/list'),
);
const CallPlanHistoryDetails = lazyLoad(
  () => import('@/pages/call-plan/history/details'),
);
/**通讯录 */
const TelephoneDirectory = lazyLoad(
  () => import('@/pages/telephone-directory'),
);
/**知识库 */
const Library = lazyLoad(() => import('@/pages/library'));
/**设置 */
const Settings = lazyLoad(() => import('@/pages/settings'));

const FwLeisure = lazyLoad(() => import('@/pages/floating-window/fw-leisure'));
const FwWorking = lazyLoad(() => import('@/pages/floating-window/fw-working'));
const FwIncoming = lazyLoad(
  () => import('@/pages/floating-window/fw-incoming'),
);
/**用户信息和拨打历史列表 */
const PhoneMessage = lazyLoad(() => import('@/pages/phone-message'));
// 问题
const Question = lazyLoad(() => import('@/pages/question'));
// 黑名单
const Blacklist = lazyLoad(() => import('@/pages/blacklist'));
// 每月活动
const PromotionalActivities = lazyLoad(
  () => import('@/pages/promotional-activities'),
);

export interface RouterModel extends RouteObject {
  // 是否显示在侧边栏
  show?: boolean | (() => boolean);
  // 权限控制，是否显示在侧边栏
  roles?: number[];
  // 侧边栏icon
  icon?: React.ReactNode;
  // 路由标题
  title?: React.ReactNode;
  // 重定向
  redirect?: string;
  // 子路由
  children?: RouterModel[];
}

export let indexRoutes: RouterModel[] = [];

export let routes: RouterModel[] = [];

export default function Routes() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isShowBigNum, remarks, userInfo } = useSelector(
    (state: RootState) => state,
  );
  const [postData] = useState<GetCallHistoryParams>({
    queryType: '0',
    page: 1,
    length: 8,
  });

  // 存储sip通话状态
  Sip.change = (type, data, msg) => {
    // console.log(Sip.websocket);
    switch (type) {
      case 'outbound':
        hangupinvisibility();
        window.electron.send('show');
        break;
      case 'incoming':
        hangupinvisibility();
        // window.electron.send('show');
        setTimeout(() => {
          window.electron.send('shineTray', '您一通新的来电');
        }, 100);
        break;
      case 'failed':
        if (
          data.originator === 'remote' &&
          data.cause === JsSIP.C.causes.UNAVAILABLE
        ) {
          window.electron.send('showNotification', {
            title: '通话失败',
            body: `线路可能存在问题`,
          });
        } else if (
          data.originator === 'remote' &&
          data.cause === JsSIP.C.causes.SIP_FAILURE_CODE
        ) {
          window.electron.send('showNotification', {
            title: '通话失败',
            body: `号码黑名单`,
          });
        } else if (
          data.originator === 'local' &&
          data.cause === JsSIP.C.causes.USER_DENIED_MEDIA_ACCESS
        ) {
          window.electron.send('showNotification', {
            title: '通话失败',
            body: `请检查是否插入麦克风或是否已授权麦克风权限`,
          });
        } else if (data.originator === '网络波动') {
          window.electron.send('showNotification', {
            title: '网络波动',
            body: data.cause,
          });
        } else {
          setTimeout(() => {
            if (
              remarks &&
              remarks !== null &&
              remarks !== undefined &&
              remarks !== ''
            ) {
              getCallHistoryList(postData)
                .then((res) => {
                  bindRemark({
                    id: [res.data.items[0].xml_cdr_uuid],
                    remark: remarks,
                  });
                })
                .catch((err) => {
                  message.error(`数据获取失败：${err}`);
                });
              dispatch({
                type: 'UPDATE_REMARKS',
                payload: '',
              });
            }
          }, 1000);
          window.electron.send('show');
          window.electron.send('showNotification', {
            title: '通话失败',
            body: JSON.stringify(msg),
          });
        }
        break;
      case 'ended':
        setTimeout(() => {
          if (remarks) {
            getCallHistoryList(postData)
              .then((res) => {
                bindRemark({
                  id: [res.data.items[0].xml_cdr_uuid],
                  remark: remarks,
                });
              })
              .catch((err) => {
                message.error(`数据获取失败：${err}`);
              });
            dispatch({
              type: 'UPDATE_REMARKS',
              payload: '',
            });
          }
        }, 1000);
        window.electron.send('show');
        break;
      case 'hangUp':
        window.electron.send('show');
        break;
      case 'confirmed':
        window.electron.send('show');
        break;
      default:
        break;
    }
    dispatch({
      type: 'UPDATE_CALL_STSATE',
      payload: {
        callState: type,
        data,
      },
    });
  };

  useEffect(() => {
    // 给假的防止报错
    if (!window.electron) {
      window.electron = {
        delElectrionStore: () => {},
        setElectronStore: () => {},
        getElectronStore: () => {},
        log: (data: any) => {
          console.log(data);
        },
        showNotification: () => {},
        send: () => {},
        sendSync: () => {
          return {
            status: 'success',
            data: '这是假的',
            message: '这是假的',
          };
        },
        sendMessage: () => {},
        on: () => {},
        once: () => {},
      };
    }
    window.electron.on('getUserInfo', () => {
      const userInfo = getUserInfo();
      if (userInfo) {
        window.electron.sendMessage({
          action: 'getUserInfo',
          payload: {
            code: 0,
            data: userInfo,
            msg: '获取用户信息',
          },
        });
      } else {
        window.electron.sendMessage({
          action: 'getUserInfo',
          payload: {
            code: 400,
            data: {},
            msg: '账号未登陆',
          },
        });
      }
    });
  }, []);
  useEffect(() => {
    // 改变窗口大小
    const appDom = document.getElementsByClassName('app');
    if (pathname === '/login') {
      // stores.config.setTheme("light")
      // stores.config.setTheme("dark")
      appDom[0].className = 'app login';
      window.electron.sendSync('changSize', {
        width: 390,
        height: 682,
      });
      window.electron.send('show');
      window.electron.send('center');
    } else if (pathname === '/floating-window/fw-leisure') {
      if (isShowBigNum) {
        appDom[0].className = 'app fw big';
        window.electron.send('changSize', {
          width: 303,
          height: 467,
        });
      } else {
        appDom[0].className = 'app fw leisure';
        window.electron.sendSync('changSize', {
          width: 303,
          height: 219,
        });
      }
      window.electron.send('show');
    } else if (pathname === '/floating-window/fw-working') {
      appDom[0].className = 'app fw outandcon';
      window.electron.sendSync('changSize', {
        width: 303,
        height: 265,
      });
    } else if (pathname === '/floating-window/fw-incoming') {
      appDom[0].className = 'app fwinc';
      window.electron.sendSync('changSize', {
        width: 391,
        height: 103,
      });
      window.electron.sendSync('move', {
        x: window.screen.availWidth / 2 - 196,
        y: 200,
      });
    } else {
      appDom[0].className = 'app';
      window.electron.sendSync('changSize', {
        width: 1215,
        height: 815,
      });
      // window.electron.send('show')
    }

    // 监听路由并存储
    if (pathname && pathname.indexOf('phone-message') !== -1) {
      dispatch({
        type: 'UPDATE_MODEL',
        payload: '客户信息(群呼)模块',
      });
      window.electron.log('进入客户信息(群呼)');
    }
    switch (pathname) {
      case '/':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '拨号轮盘模块',
        });
        window.electron.log('进入拨号轮盘');
        break;
      case '/group-call/list':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '群呼列表模块',
        });
        window.electron.log('进入群呼列表');
        break;
      case '/call-history/list':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '通话列表模块',
        });
        window.electron.log('进入通话列表');
        break;
      case '/call-plan/list':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '拨打计划列表模块',
        });
        window.electron.log('进入拨打计划(列表)');
        break;
      case '/call-plan/details':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '拨打计划查看详情模块',
        });
        window.electron.log('进入拨打计划(查看详情)');
        break;
      case '/call-plan/history':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '拨打计划历史批次模块',
        });
        window.electron.log('进入拨打计划(历史批次)');
        break;
      case '/telephone-directory':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '通讯录模块',
        });
        window.electron.log('进入通讯录');
        break;
      case '/library':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '知识库模块',
        });
        window.electron.log('进入知识库');
        break;
      case '/settings':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '系统设置模块',
        });
        window.electron.log('进入系统设置');
        break;
      case '/floating-window/fw-leisure':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '最小拨号浮窗模块(拨打)',
        });
        window.electron.log('最小拨号浮窗模块(拨打)');
        break;
      case '/floating-window/fw-incoming':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '最小拨号浮窗模块(来电)',
        });
        window.electron.log('最小拨号浮窗模块(来电)');
        break;
      case '/floating-window/fw-working':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '最小拨号浮窗模块(呼出、通话)',
        });
        window.electron.log('最小拨号浮窗模块(呼出、通话)');
        break;
      case '/question':
        dispatch({
          type: 'UPDATE_MODEL',
          payload: '常见问题',
        });
        window.electron.log('进入常见问题模块');
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    indexRoutes = [
      {
        path: '/',
        title: '拨号轮盘',
        show: true,
        icon: <IconFont type='icon-Phone_call' />,
        element: Home,
      },
      {
        path: '/group-call',
        title: '群呼列表',
        show: checkExtionType(1),
        icon: <IconFont type='icon-group' />,
        redirect: '/group-call/list',
        children: [
          {
            path: 'list',
            title: '',
            element: GroupCallList,
            show: false,
          },
          {
            path: 'list/details/:BatchId',
            title: '群呼详情',
            element: GroupCallDetails,
            show: false,
          },
        ],
      },
      {
        path: '/call-history',
        title: '通话列表',
        show: true,
        icon: <IconFont type='icon-file-text' />,
        redirect: '/call-history/list',
        children: [
          {
            path: 'list',
            title: '',
            element: CallHistoryList,
            show: false,
          },
        ],
      },
      {
        path: '/call-plan',
        title: '拨打计划',
        redirect: '/call-plan/list',
        show: true,
        icon: <IconFont type='icon-Calendar_check' />,
        children: [
          {
            path: 'list',
            title: '',
            element: CallPlanList,
            show: false,
          },
          {
            path: 'details',
            title: '查看详情',
            element: CallPlanDetails,
            show: false,
          },
          {
            path: 'history',
            title: '历史批次列表',
            show: false,
            children: [
              {
                path: 'list',
                title: '',
                element: CallPlanHistoryList,
                show: false,
              },
              {
                path: 'details/:id',
                title: '历史批次详情',
                element: CallPlanHistoryDetails,
                show: false,
              },
            ],
          },
        ],
      },
      {
        path: '/telephone-directory',
        title: '通讯录',
        show: false,
        icon: <IconFont type='icon-user_2' />,
        children: [
          {
            path: '',
            title: '',
            element: TelephoneDirectory,
            show: false,
          },
        ],
      },
      {
        path: '/library',
        title: '知识库',
        show: false,
        icon: <IconFont type='icon-zhishiku' />,
        element: Library,
      },
      {
        path: '/settings',
        title: '系统设置',
        show: true,
        icon: <SettingFilled />,
        element: Settings,
      },
      {
        path: '/phone-message/:id',
        title: '群呼查看详情',
        show: false,
        element: PhoneMessage,
      },
      {
        path: '/question',
        title: '常见问题',
        show: false,
        element: Question,
      },
      {
        path: '/blacklist',
        title: '黑名单',
        show: false,
        element: Blacklist,
      },
      {
        path: '/promotional-activities',
        title: '每月活动',
        show: false,
        element: PromotionalActivities,
      },
      // {
      // 	path: "",
      // 	title: "更多功能",
      // 	show: true,
      // 	icon: <IconFont type="icon-a-More-horizontal1" />,
      // 	element: Settings,
      // },
    ];
    routes = [
      {
        path: '/login',
        title: '登陆',
        element: Login,
      },
      {
        path: '/',
        element: Layout,
        show: false,
        children: indexRoutes,
      },
      {
        path: '/floating-window',
        title: '通话浮窗',
        show: false,
        redirect: '/floating-window/fw-leisure',
        children: [
          {
            path: 'fw-leisure',
            element: FwLeisure,
          },
          {
            path: 'fw-incoming',
            element: FwIncoming,
          },
          {
            path: 'fw-working',
            element: FwWorking,
          },
        ],
      },
      // {
      //   path: "/404",
      //   show: false,
      //   element: <Err404 />,
      // },
      // {
      //   path: "*",
      //   show: false,
      //   element: <Err404 />,
      // },
    ];
  }, [userInfo]);
  return useRoutes(routes);
}
