// 切换耳机
function switchAudioOutputDevice(
  selectedOutputDeviceId: string,
): Promise<void> {
  const audioElements = document.querySelectorAll('audio');
  const promises: Promise<void>[] = [];

  audioElements.forEach((audioElement) => {
    if (!('setSinkId' in HTMLAudioElement.prototype)) {
      console.error('setSinkId 方法在此浏览器中不可用。');
      return;
    }

    const promise = audioElement
      .setSinkId(selectedOutputDeviceId)
      .then(() => {
        // 假设 setOutputValue 是一个已定义的函数，用于更新界面或状态以反映当前选择的输出设备
        console.log(`音频输出设备切换到 ${selectedOutputDeviceId} 成功`);
      })
      .catch((err: Error) => {
        console.error(`切换音频输出设备失败：`, err);
        throw err; // 抛出错误，让外部调用者处理
      });

    promises.push(promise);
  });

  // 使用 Promise.all 等待所有切换操作完成
  return Promise.all(promises)
    .then(() => {})
    .catch((err) => {
      // 可以选择在这里处理所有错误，或者将错误向外抛出让调用者处理
      throw err;
    });
}

function switchMicrophone(selectedMicrophoneId: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: { deviceId: selectedMicrophoneId } })
      .then((stream) => {
        const audioTracks = stream.getAudioTracks();
        if (audioTracks.length > 0) {
          // 关闭当前正在使用的麦克风轨道
          audioTracks[0].stop();
        }
        // 重新获取用户媒体流，以切换到新的麦克风设备
        return navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: selectedMicrophoneId } },
        });
      })
      .then((newStream) => {
        // 在这里可以处理新的媒体流，比如更新音频元素的源
        console.log('切换到新的麦克风设备成功！');
        const deviceId = newStream.getAudioTracks()[0].getSettings().deviceId;
        console.log(deviceId, selectedMicrophoneId);

        resolve();
      })
      .catch((err) => {
        console.error('切换麦克风设备失败：', err);
        reject(err);
      });
  });
}

export { switchAudioOutputDevice, switchMicrophone };
