import { LineItem } from 'typings/user';
import ajax from '@/utils/ajax';
import ajax2 from '@/utils/ajax2';

/**
 * 获取线路数据
 * @param username 账号
 * @param password 密码
 * @returns listData
 */

export function getLines() {
  return ajax<LineItem[]>({
    url: '/FcomHuihu/V1/Client/ExtensionIsBindLineAndLineCode',
    method: 'GET',
  });
}

export function extensionIsBindLineAndLineCode(data: {
  username: string;
  password: string;
}) {
  return ajax({
    url: 'http://192.168.1.131:50/api/FcomHuihu/V1/Extensions/ExtensionIsBindLineAndLineCode',
    method: 'GET',
    params: data,
  });
}

// 根据被叫获取号码库中的线路
export function getPhoneNumber(data: { PhoneNumber: string }) {
  return ajax2<{
    lineCode: string;
    phoneNumber: string;
  }>({
    url: '/FcomHuihu/V1/Client/PhoneNumber',
    method: 'POST',
    data,
  });
}
