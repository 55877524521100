import ajax from "@/utils/ajax"

export interface blacklistListParams{
  PhoneNumber?: string
}
export interface blacklistListModel{
  items?: blacklistListModelItem[]
  total?: number
  page?: number
  length?: number
}
export interface blacklistListModelItem{
  id: string
  phoneNumber: string	
  level: number	
  // 后台名称	
  realName: string	
  // 客户端坐席
  extension: string	
}
// 列表
export function blacklistList(data: blacklistListParams){
  return ajax<blacklistListModel>({
    url: '/FcomHuihu/V1/ClientApi/PhoneBlock/GetList',
    method: 'GET',
    params: data
  })
}
// 添加
export function blacklistAdd(data: { PhoneNumber: string }){
  return ajax({
    url: '/FcomHuihu/V1/ClientApi/PhoneBlock/Add',
    method: 'POST',
    data
  })
}
// 编辑
export function blacklistEdit(data: { PhoneNumber: string, Id: string }){
  return ajax({
    url: '/FcomHuihu/V1/ClientApi/PhoneBlock/Edit',
    method: 'POST',
    data
  })
}
// 删除
export function blacklistDel(data: { Id: string }){
  return ajax({
    url: '/FcomHuihu/V1/ClientApi/PhoneBlock/Delete',
    method: 'POST',
    data
  })
}
// 导入
export function blacklistUp(data: FormData) {
  return ajax({
    url: '/FcomHuihu/V1/ClientApi/PhoneBlock/Import',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  });
}