import ajax2 from '@/utils/ajax2';

/**
 * 检查号码限制
 */
export function checkPhoneNumberIsCall(data: { PhoneNumber: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/Line/CheckPhoneNumberIsCall',
    method: 'POST',
    data,
  });
}


/**
 * 添加号码呼叫次数
 */
export function addPhoneNumberCallCount(data: { PhoneNumber: string }) {
  return ajax2<boolean>({
    url: '/FcomHuihu/V1/ClientApi/Line/AddPhoneNumberCallCount',
    method: 'POST',
    data,
  });
}