/**
 *
 * @param str
 * @returns
 */
export default function getStringNumber(str: string): string | null {
  const result = str.match(/\d+/g);
  if (result) {
    return result.join('');
  }
  return result;
}

/**
 * 判断手机、电话号码是否正确
 * @param number 手机、电话号码
 * @returns boolean true || false
 */
// function checkNumber(number: string): boolean {
//   /**
//    * 判断手机
//    */
//   if (!/^1[3|4|5|7|8]\d{9}$/.test(number)) {
//     return false;
//   }
//   /**
//    * 判断电话
//    */
//   if (!/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(number)) {
//     return false;
//   }
//   return true;
// }
