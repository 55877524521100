import ajax from '@/utils/ajax';
import { UserInfo } from 'typings/user';

/**
 * 登陆的参数
 * username 账号
 * password 密码
 * captcha 验证码
 */
export interface LoginParams {
  extension: string;
  password: string;
}

export function login(data: LoginParams) {
  return ajax<UserInfo>({
    url: '/FcomHuihu/V1/Client/Login',
    method: 'POST',
    data,
  });
}

export interface ViewCompanyModel {
  id?: string;
  viName?: string;
  viKey?: string;
  isEnable?: number;
  isEdit?: number;
}

export function viewCompany() {
  return ajax<ViewCompanyModel[]>({
    url: '/FcomHuihu/V1/Client/ViewCompany',
    method: 'GET',
  });
}

export function hangupinvisibility() {
  return ajax({
    url: '/FcomHuihu/V1/Client/HangUpCall',
    method: 'GET',
  });
}

// type keys = 'yesterday' | 'today'
export type currentDataModel = {
  [key in 'yesterday' | 'today']: {
    // 接通总数
    answerCount: number;
    // 接通秒数
    billsec: number;
    // 呼叫总数
    callCount: number;
    createTime?: string;
    currentTime?: string;
    day?: number;
    extensionId?: string;
    id?: string;
    month?: number;
    updateTime?: string;
    year?: number;
  };
} & {
  currentTotalCount: number;
  yesterdayTotalCount: number;
};
//获取今日数据
export function currentData() {
  return ajax<currentDataModel>({
    url: '/FcomHuihu/V1/Client/CurrentData',
    method: 'GET',
  });
}
