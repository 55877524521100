/**
 * 加密文本内容中的手机号码
 * 前三后四
 */
export function encodeMobile(text: string) {
  const regex = /\d{11}/g;
  return text.replace(regex, (number) => {
    return number.replace(/^(.{3}).+(.{4})$/, '$1****$2');
  });
}

// 判断是否为数字
export function isNumber(value: string | number) {
  return typeof value === 'number' && !Number.isNaN(value);
}
