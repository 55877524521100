import { makeAutoObservable } from 'mobx';

export type PrimaryColorsModel = {
  key: string;
  primaryColor: string;
  primaryColorHover: string;
  primaryColorActive: string;
};

class Config {
  // 主题
  theme = 'light';
  // 选中的主色
  primaryColor = '';
  // 主题主色列表
  primaryColors: PrimaryColorsModel[] = [
    {
      key: '1',
      primaryColor: '#21C3B5',
      primaryColorHover: '#21C3B5',
      primaryColorActive: '#21C3B5',
    },
    {
      key: '2',
      primaryColor: '#47ADED',
      primaryColorHover: '#47ADED',
      primaryColorActive: '#47ADED',
    },
    {
      key: '3',
      primaryColor: '#712FF4',
      primaryColorHover: '#712FF4',
      primaryColorActive: '#712FF4',
    },
    {
      key: '4',
      primaryColor: '#FA8888',
      primaryColorHover: '#FA8888',
      primaryColorActive: '#FA8888',
    },
    {
      key: '5',
      primaryColor: '#FA8226',
      primaryColorHover: '#FA8226',
      primaryColorActive: '#FA8226',
    },
    {
      key: '6',
      primaryColor: '#E9CD17',
      primaryColorHover: '#E9CD17',
      primaryColorActive: '#E9CD17',
    },
    {
      key: '7',
      primaryColor: '#21C3B5',
      primaryColorHover: '#21C3B5',
      primaryColorActive: '#21C3B5',
    },
    {
      key: '8',
      primaryColor: '#23C688',
      primaryColorHover: '#23C688',
      primaryColorActive: '#23C688',
    },
  ];

  constructor() {
    const theme = localStorage.getItem('theme');
    const primaryColorJson = localStorage.getItem('primaryColor');

    if (theme) {
      this.setTheme(theme);
    }

    if (primaryColorJson) {
      this.setPrimaryColor(JSON.parse(primaryColorJson));
    }
    makeAutoObservable(this);
  }
  setTheme(val: string) {
    localStorage.setItem('theme', val);
    this.theme = val;
    document.body.className = val;

    localStorage.removeItem('primaryColor');
    document.body.style.removeProperty('--primary-color');
    document.body.style.removeProperty('--primary-color-hover');
  }
  setPrimaryColor(item: PrimaryColorsModel) {
    localStorage.setItem('primaryColor', JSON.stringify(item));

    const bodyStyle = document.body.style;
    bodyStyle.setProperty('--primary-color', item.primaryColor);
    bodyStyle.setProperty('--primary-color-hover', item.primaryColorHover);
  }
}
const config = new Config();
export default config;
