import { UserInfo } from 'typings/user';

/**
 * 获取用户登陆信息
 * @returns UserInfo || null
 */
export default function getUserInfo(): UserInfo | null {
  const USER_INFO_JSON = sessionStorage.getItem('userInfo');
  if (USER_INFO_JSON) {
    try {
      const USERINFO = JSON.parse(USER_INFO_JSON);
      return USERINFO;
    } catch (error) {
      window.electron.log('用户登陆信息解析失败');
      return null;
    }
  } else {
    return null;
  }
}
