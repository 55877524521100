import { store } from '@/store';
import Sip from './sip';

export default function logout() {
  store.dispatch({
    type: 'UPDATE_USERINFO',
    payload: null,
  });
  Sip.unReg();
  window.sessionStorage.removeItem('userInfo');
  window.location.href = '/login';
}
