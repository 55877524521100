import ajax from '@/utils/ajax';
import React from 'react';

export interface BindRemarkParams {
  id: React.Key[] | string[];
  remark: string;
}
/**
 *  编辑 || 绑定备注
 * @param postData 请求
 * @returns
 */
export function bindRemark(postData: BindRemarkParams) {
  return ajax({
    url: '/FcomHuihu/V1/Client/CdrBindRemark',
    method: 'POST',
    data: postData,
  });
}
